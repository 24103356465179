<template>
  <v-container fluid>
    <v-overlay absolute v-model="overlay">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <template v-if="!overlay && getters_single_staff">
      <v-row>
        <v-col cols="12" lg="4" class="pr-1">
          <v-card outlined tile>
            <v-avatar tile size="300">
              <input
                class="d-none"
                type="file"
                ref="image"
                accept="image/*"
                @change="changeDp"
              />
              <v-img
                :src="
                  getters_single_staff.avatar_url
                    ? getters_single_staff.avatar_url
                    : require('@/assets/download.jpg')
                "
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      class="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <v-btn
                  style="bottom: 15px"
                  color="success"
                  absolute
                  fab
                  right
                  bottom
                  @click="$refs.image.click()"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-img>
            </v-avatar>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 font-weight-medium text-wrap">
                  {{
                    `${getters_single_staff.title} ${getters_single_staff.othernames} ${getters_single_staff.lastname}`
                  }}
                </v-list-item-title>
                <template v-if="getters_single_staff.employment">
                  <v-list-item-subtitle>
                    {{
                      `${getters_single_staff.employment.organogram.office_name} ${getters_single_staff.employment.organogram.office_type}`
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="getters_single_staff.employment.staff_position"
                  >
                    {{
                      `${getters_single_staff.employment.staff_position.position}`
                    }}
                  </v-list-item-subtitle>
                </template>
                <template v-else>
                  <v-list-item-subtitle>
                    <b>[</b> No Department Provided <b>]</b>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <b>[</b> No Position Provided <b>]</b>
                  </v-list-item-subtitle>
                </template>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item two-line>
              <v-list-item-content>
                <template v-if="getters_single_staff.employment">
                  <v-list-item-title>
                    Employee ID:
                    {{ `${getters_single_staff.employment.employee_id}` }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Date of Join:
                    {{
                      moment(
                        getters_single_staff.employment.employment_date
                      ).format("Do MMMM YYYY")
                    }}
                  </v-list-item-subtitle>
                </template>
                <template v-else>
                  <v-list-item-subtitle>
                    <b>[</b> No Employee ID Provided <b>]</b>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <b>[</b> Date of Join Not Provided <b>]</b>
                  </v-list-item-subtitle>
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" lg="8">
          <v-card outlined class="pa-0" tile>
            <v-tabs
              class="rounded-0"
              dark
              background-color="primary"
              next-icon="mdi-arrow-right-bold-box-outline"
              prev-icon="mdi-arrow-left-bold-box-outline"
              v-model="tab"
              show-arrows
            >
              <v-tab
                class="text-capitalize body-2 fixed-tabs-bar"
                v-for="(item, index) in status"
                :key="index"
              >
                {{ `${item.type}` }}
              </v-tab>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, index) in status" :key="index">
                  <template>
                    <component :is="item.componentname" />
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    inject,
    onMounted,
    provide,
    reactive,
    toRefs,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Contact from "@/components/Staff/View/Contact";
  import Bank from "@/components/Staff/View/Bank";
  import Employment from "@/components/Staff/View/Employment";
  import Qualification from "@/components/Staff/View/Qualification";
  import Spouse from "@/components/Staff/View/Spouse";
  import Children from "@/components/Staff/View/Children";
  import Parent from "@/components/Staff/View/Parent";
  import Kin from "@/components/Staff/View/Kin";
  import Death from "@/components/Staff/View/Death";
  import Files from "@/components/Staff/View/Files";
  import Api from "@/apis/Api";
  import Response from "@/components/ActionResponse/Response";

  export default defineComponent({
    components: {
      Response,
      Contact,
      Employment,
      Qualification,
      Bank,
      Spouse,
      Children,
      Parent,
      Kin,
      Death,
      Files,
    },

    setup() {
      const vm = getCurrentInstance();
      const store = vm.proxy.$store;
      const { getSingleStaff, signOut } = useActions([
        "getSingleStaff",
        "signOut",
      ]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const staff_id = inject("staff_id");

      const data = reactive({
        overlay: true,
        tab: null,
        status: [
          { type: "General Information", componentname: "contact" },
          { type: "Employment", componentname: "employment" },
          { type: "Education & Qualification", componentname: "qualification" },
          { type: "Bank", componentname: "bank" },
          { type: "Spouse", componentname: "spouse" },
          { type: "Children", componentname: "children" },
          { type: "Parent", componentname: "parent" },
          { type: "Next of Kin", componentname: "kin" },
          { type: "Death Gratuity", componentname: "death" },
          { type: "Documents", componentname: "files" },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
        response: "",
      });

      const { overlay, msgHeader, msgBody, msgIcon, color, response } =
        toRefs(data);

      const changeDp = async (e) => {
        const image = e.target.files[0];

        const formData = new FormData();
        formData.append("imageDp", image);
        formData.append("user_id", getters_single_staff.value.id);
        await Api()
          .post("/user_avatar", formData)
          .then((res) => {
            store.commit("SET_SINGLE_STAFF", res.data.data);
          })
          .catch(() => {});
      };

      provide("color", color);

      onMounted(() => {
        if (staff_id.value) {
          getSingleStaff(staff_id.value)
            .catch((e) => {
              response.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              color.value = "error";
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  signOut().then(() => {
                    vm.proxy.$router.replace({
                      name: "Login",
                    });
                  });
                  break;
                case 403:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value =
                    "Try Again Later Or Call The System Administrator";
                  break;
              }
            })
            .finally(() => {
              overlay.value = false;
            });
        }
      });

      return {
        staff_id,
        ...toRefs(data),
        getters_single_staff,
        changeDp,
      };
    },
  });
</script>
<style>
  .fixed-tabs-bar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
  }
</style>
