<template>
  <v-container fluid v-if="getters_single_staff.kin">
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="mx-2"
        dark
        fixed
        bottom
        fab
        @click="editKin"
        color="success"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <v-simple-table v-if="getters_single_staff">
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Name of Kin</th>
            <td>{{ getters_single_staff.kin.kin_name }}</td>
          </tr>
          <tr>
            <th>Date of Birth</th>
            <td>{{ getters_single_staff.kin.kin_dob }}</td>
          </tr>
          <tr>
            <th>Hometown</th>
            <td>{{ getters_single_staff.kin.kin_hometown }}</td>
          </tr>
          <tr>
            <th>Phone Number 1</th>
            <td>{{ getters_single_staff.kin.kin_number1 }}</td>
          </tr>
          <tr>
            <th>Phone Number 2</th>
            <td>{{ getters_single_staff.kin.kin_number2 }}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{{ getters_single_staff.kin.kin_address }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
  <v-container v-else fluid>
    <v-card flat>
      <v-card-text>
        <v-container fluid>
          <div class="text-h6">No Next of Kin Record Found</div>

          <v-dialog
            v-model="dialog"
            max-width="1000px"
            transition="dialog-transition"
          >
            <v-sheet color="white">
              <NextOfKin :user_id="getters_single_staff.id" />
            </v-sheet>
          </v-dialog>

          <v-footer app color="transparent">
            <v-spacer></v-spacer>
            <v-btn class="mx-2" dark fab @click="dialog = true" color="success">
              <v-icon large dark>mdi-plus</v-icon>
            </v-btn>
          </v-footer>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import NextOfKin from "@/components/Staff/Create/NextOfKin";

  export default defineComponent({
    components: { NextOfKin },
    setup() {
      const data = reactive({ dialog: false });
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const editKin = debounce(() => {
        emitter.emit("edit-kin", true);
      });

      return {
        ...toRefs(data),
        getters_single_staff,
        editKin,
      };
    },
  });
</script>
