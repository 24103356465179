<template>
  <v-container fluid v-if="getters_single_staff.child.length > 0">
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="mx-2"
        dark
        fixed
        bottom
        fab
        @click="editChildren"
        color="success"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="getters_single_staff.child"
      disable-pagination
      hide-default-footer
      class="elevation-0"
      item-key="id"
    >
    </v-data-table>
  </v-container>
  <v-card v-else flat>
    <v-card-text>
      <v-container fluid>
        <div class="text-h6">No Child/Children Record Found</div>

        <v-dialog
          v-model="dialog"
          max-width="1000px"
          transition="dialog-transition"
        >
          <v-sheet color="white">
            <Children :user_id="getters_single_staff.id" />
          </v-sheet>
        </v-dialog>

        <v-btn
          class="mx-2"
          dark
          fab
          fixed
          bottom
          right
          @click="dialog = true"
          color="success"
        >
          <v-icon large dark>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import Children from "@/components/Staff/Create/Children";

  export default defineComponent({
    components: { Children },
    setup() {
      const data = reactive({
        dialog: false,
        headers: [
          { text: "NAME", value: "name" },
          { text: "DATE of BIRTH", value: "dob" },
          { text: "BIRTHPLACE", value: "birthplace" },
          { text: "RELATIONSHIP", value: "relationship" },
        ],
      });
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const editChildren = debounce(() => {
        emitter.emit("edit-children", true);
      });

      return {
        ...toRefs(data),
        getters_single_staff,
        editChildren,
      };
    },
  });
</script>
