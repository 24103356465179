<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card v-if="items.length > 0">
          <v-virtual-scroll
            :items="items"
            :height="(items.length + 0.5) * 40"
            item-height="56"
          >
            <template v-slot:default="{ item, index }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-slide-x-reverse-transition>
                    <v-btn
                      icon
                      small
                      color="red"
                      @click="removeRow(items, index, item.filename)"
                    >
                      <v-icon> mdi-delete</v-icon>
                    </v-btn>
                  </v-slide-x-reverse-transition>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.filename }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    link
                    @click="doAction(item.filename)"
                    icon
                    color="primary"
                  >
                    <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          id="hoverCard"
          @drop.prevent="sendFile"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          min-height="200"
          class="pa-2"
          :class="{
            'grey lighten-1': dragover,
            'white--text': dragover,
          }"
          elevation="0"
          color="grey darken-1"
          :style="{ outline: dragover ? '4px dashed white' : outline }"
          style="cursor: pointer; outline-offset: -10px"
        >
          <input
            style="
              opacity: 0;
              width: 100%;
              position: absolute;
              height: 200px;
              cursor: pointer;
            "
            ref="uploader"
            type="file"
            accept="image/*"
            @change="sendFileInput"
            multiple
          />
          <div class="text-center headline" style="padding: 70px 0">
            Drag and drop, or Click to Upload files
            <div class="text-center">
              <v-icon x-large :class="{ 'white--text': dragover }">
                mdi-cloud-upload
              </v-icon>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <p class="body-1">
      Acceptable File Types:
      <span class="font-weight-bold"> .pdf, .jpeg, .jpg and .png only</span>
    </p>

    <v-row justify="center" v-if="uploadedFiles.length > 0">
      <v-col
        cols="12"
        md="4"
        lg="4"
        v-for="(preview, index) in uploadedFiles"
        :key="index"
      >
        <v-sheet
          auto-height
          class="v-sheet--offset mx-auto"
          color="white"
          max-width="calc(100% - 20px)"
        >
          <v-hover v-slot="{ hover }">
            <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
              <v-img class="" :src="preview.pix" :aspect-ratio="16 / 9">
                <v-expand-transition :key="index">
                  <div class="d-flex justify-space-between">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="removeImage(preview.name)"
                          absolute
                          icon
                          v-bind="attrs"
                          v-on="on"
                          right
                          small
                          class="mt-1"
                          :class="{ 'show-btns': hover }"
                          :color="transparent"
                        >
                          <v-icon
                            :color="transparent"
                            :class="{ 'show-btns': hover }"
                          >
                            mdi-close-circle
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Click to delete</span>
                    </v-tooltip>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-card>
          </v-hover>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="validate"
        >
          Save Changes
          <template v-slot:loader>
            <span>Saving...</span>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { defineComponent, getCurrentInstance, inject, onMounted, ref } from "vue";
  import Api from "@/apis/Api";
  import { useGetters } from "vuex-composition-helpers";
  export default defineComponent({
    setup() {
      const vm = getCurrentInstance()
      const store = vm.proxy.$store;
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const uploading = ref(false);
      const files = ref([]);
      const uploadedFiles = ref([]);
      const dragover = ref(false);
      const transparent = ref("rgba(255, 255, 255, 0)");
      const outline = "4px dashed #424242";
      const uploader = ref(null);
      const items = ref([]);
      const formData = new FormData();
      const deletedItems = ref([]);
      const loading = ref(false);

      const staff_id = inject("staff_id");

      const sendFile = (e) => {
        console.log(files.value);
        try {
          files.value = e.dataTransfer.files;
          dragover.value = false;

          for (let file of files.value) {
            let i = 0;
            formData.append("documents[" + i++ + "]", file);
            let fileType = file.type;
            let validType = [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "application/pdf",
            ];

            let condition = validType.indexOf(fileType) === -1;

            if (condition) {
              alert("error");
              return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
              uploadedFiles.value.push({
                name: file.name,
                size: file.size,
                pix: e.target.result,
              });
            };
          }
        } catch (error) {
          console.log(error);
        }
      };

      const sendFileInput = (e) => {
        files.value = e.target.files;
        let x = 0;
        for (let i of files.value) {
          formData.append("documents[" + x++ + "]", i);
          const reader = new FileReader();
          reader.readAsDataURL(i);
          reader.onload = (e) => {
            uploadedFiles.value.push({
              name: i.name,
              size: i.size,
              pix: e.target.result,
            });
          };
        }

        uploader.value = "";
      };

      const removeImage = (img) => {
        const index = uploadedFiles.value.findIndex((item) => item.name === img);
        if (index > -1) uploadedFiles.value.splice(index, 1);
      };

      const validate = () => {
        if (uploadedFiles.value.length > 0 || deletedItems.value > 0) {
          loading.value = true;
          formData.append("deletedItems", JSON.stringify(deletedItems.value));
          formData.append("user_id", getters_single_staff.value.id);

          Api()
            .post("/user_document", formData)
            .then((res) => {
              store.commit("SET_SINGLE_STAFF", res.data.data);
              items.value = getters_single_staff.value.documents;
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => (loading.value = false));
        }
      };

      const doAction = (item) => {
        window.open(
          `http://localhost:8000/${getters_single_staff.value.id}/${item}`,
          "_blank"
        );
      };

      const removeRow = (array, i, filename) => {
        array.splice(i, 1);
        if (filename) {
          deletedItems.value.push(filename);
          return;
        }
      };

      onMounted(() => (items.value = getters_single_staff.value.documents));

      return {
        removeRow,
        doAction,
        uploading,
        sendFile,
        sendFileInput,
        transparent,
        uploadedFiles,
        files,
        dragover,
        removeImage,
        validate,
        outline,
        items,
        loading,
        staff_id,
      };
    },
  });
</script>
<style scoped>
  #hoverCard {
    transition: opacity 0.4s ease-in-out;
  }

  #hoverCard:not(.on-hover) {
    opacity: 0.8;
  }

  .show-btns {
    color: #002e69 !important;
  }

  .repeating-gradient {
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 1) 5px,
      rgba(206, 206, 206, 0.25) 20px
    );
  }
</style>
