<template>
  <v-container fluid v-if="getters_single_staff.bank">
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="mx-2"
        dark
        fixed
        bottom
        fab
        @click="editBank"
        color="success"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <v-simple-table v-if="getters_single_staff">
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Account Number</th>
            <td>{{ getters_single_staff.bank.account_number }}</td>
          </tr>
          <tr>
            <th>Bank</th>
            <td>{{ getters_single_staff.bank.bank_name }}</td>
          </tr>
          <tr>
            <th>Branch</th>
            <td>{{ getters_single_staff.bank.branch }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
  <v-card v-else flat>
    <v-card-text>
      <v-container fluid>
        <div class="text-h6">No Bank Record Found</div>

        <v-dialog
          v-model="dialog"
          max-width="1000px"
          transition="dialog-transition"
        >
          <v-sheet color="white">
            <Bank :user_id="getters_single_staff.id" />
          </v-sheet>
        </v-dialog>

        <v-btn
          class="mx-2"
          dark
          fab
          fixed
          bottom
          right
          @click="dialog = true"
          color="success"
        >
          <v-icon large dark>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import Bank from "@/components/Staff/Create/Bank";

  export default defineComponent({
    components: { Bank },
    setup() {
      const data = reactive({ dialog: false });
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const editBank = debounce(() => {
        emitter.emit("edit-bank", true);
      });

      return {
        ...toRefs(data),
        getters_single_staff,
        editBank,
      };
    },
  });
</script>
