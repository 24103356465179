<template>
  <v-card flat>
    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Spouse"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Name of spouse"
                      v-model="spouseName"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-menu
                    v-model="menuSpouse"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Spouse date of birth"
                        rules="required"
                      >
                        <v-text-field
                          v-model="spouseDob"
                          label="Date of birth"
                          readonly
                          outlined
                          hide-details="auto"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="spouseDob"
                      @input="menuSpouse = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Hometown"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Hometown"
                      v-model="spouseHometown"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile number 1"
                    :rules="{
                      required: true,
                      digits: 10,
                      regex: '^(0)\\d{9}$',
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Phone number 1"
                      v-model="spouseNumber1"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Mobile number 2"
                    :rules="{
                      regex: spouseNumber2 ? '^(0)\\d{9}$' : '',
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      label="Phone number 2 (optional)"
                      v-model="spouseNumber2"
                      :error-messages="errors"
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date of birth"
                    rules="required"
                  >
                    <v-text-field
                      hide-details="auto"
                      outlined
                      label="Address"
                      :error-messages="errors"
                      v-model="spouseAddress"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-card-actions class="ml-2">
      <v-btn color="success" @click="sendData" class="font-weight-bold">
        save changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import { defineComponent, reactive, toRefs } from "vue";
  import { useActions } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { digits, regex, required } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  must be valid",
  });

  export default defineComponent({
    props: ["user_id"],
    components: { ValidationProvider, ValidationObserver },
    setup(props) {
      const { userSpouse } = useActions(["userSpouse"]);
      const spouseForm = reactive({
        observer: null,
        valid: true,
        menuSpouse: false,
        spouseName: null,
        spouseDob: null,
        spouseAddress: null,
        spouseNumber1: null,
        spouseNumber2: null,
        spouseHometown: null,
        basketSpouse: null,
        menuDob: false,
      });

      const {
        observer,
        spouseDob,
        spouseName,
        spouseAddress,
        spouseNumber1,
        spouseNumber2,
        spouseHometown,
        basketSpouse,
      } = toRefs(spouseForm);

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            basketSpouse.value = {
              spouse: spouseName.value,
              dob: spouseDob.value,
              address: spouseAddress.value,
              phone_number1: spouseNumber1.value,
              phone_number2: spouseNumber2.value,
              user_id: props.user_id,
              hometown: spouseHometown.value,
            };

            userSpouse(basketSpouse.value);
          }
        });
      };

      return {
        ...toRefs(spouseForm),
        sendData,
      };
    },
  });
</script>
