var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Spouse","rules":{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Name of spouse","error-messages":errors,"outlined":""},model:{value:(_vm.spouseName),callback:function ($$v) {_vm.spouseName=$$v},expression:"spouseName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":"Spouse date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.spouseDob),callback:function ($$v) {_vm.spouseDob=$$v},expression:"spouseDob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menuSpouse),callback:function ($$v) {_vm.menuSpouse=$$v},expression:"menuSpouse"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuSpouse = false}},model:{value:(_vm.spouseDob),callback:function ($$v) {_vm.spouseDob=$$v},expression:"spouseDob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Hometown","rules":{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Hometown","error-messages":errors,"outlined":""},model:{value:(_vm.spouseHometown),callback:function ($$v) {_vm.spouseHometown=$$v},expression:"spouseHometown"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 1","rules":{
                    required: true,
                    digits: 10,
                    regex: '^(0)\\d{9}$',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Phone number 1","error-messages":errors,"outlined":""},model:{value:(_vm.spouseNumber1),callback:function ($$v) {_vm.spouseNumber1=$$v},expression:"spouseNumber1"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mobile number 2","rules":{
                    regex: _vm.spouseNumber2 ? '^(0)\\d{9}$' : '',
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","label":"Phone number 2 (optional)","error-messages":errors,"outlined":""},model:{value:(_vm.spouseNumber2),callback:function ($$v) {_vm.spouseNumber2=$$v},expression:"spouseNumber2"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"Date of birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","label":"Address","error-messages":errors},model:{value:(_vm.spouseAddress),callback:function ($$v) {_vm.spouseAddress=$$v},expression:"spouseAddress"}})]}}])})],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"success"},on:{"click":_vm.sendData}},[_vm._v(" save changes ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }