<template>
  <v-container fluid v-if="getters_single_staff.parent">
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="mx-2"
        dark
        fixed
        bottom
        fab
        @click="editParent"
        color="success"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
      <v-row>
        <v-col cols="12" lg="6">
          <v-simple-table v-if="getters_single_staff">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>FATHER</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Name of Father</th>
                  <td>{{ getters_single_staff.parent.father_name }}</td>
                </tr>
                <tr>
                  <th>Date of Birth</th>
                  <td>{{ getters_single_staff.parent.father_dob }}</td>
                </tr>
                <tr>
                  <th>Hometown</th>
                  <td>{{ getters_single_staff.parent.father_hometown }}</td>
                </tr>
                <tr>
                  <th>Phone Number 1</th>
                  <td>{{ getters_single_staff.parent.father_number1 }}</td>
                </tr>
                <tr>
                  <th>Phone Number 2</th>
                  <td>{{ getters_single_staff.parent.father_number2 }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{{ getters_single_staff.parent.father_address }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" lg="6">
          <v-simple-table v-if="getters_single_staff">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>MOTHER</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Name of Mother</th>
                  <td>{{ getters_single_staff.parent.mother_name }}</td>
                </tr>
                <tr>
                  <th>Date of Birth</th>
                  <td>{{ getters_single_staff.parent.mother_dob }}</td>
                </tr>
                <tr>
                  <th>Hometown</th>
                  <td>{{ getters_single_staff.parent.mother_hometown }}</td>
                </tr>
                <tr>
                  <th>Phone Number 1</th>
                  <td>{{ getters_single_staff.parent.mother_number1 }}</td>
                </tr>
                <tr>
                  <th>Phone Number 2</th>
                  <td>{{ getters_single_staff.parent.mother_number2 }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{{ getters_single_staff.parent.mother_address }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <v-card v-else flat>
    <v-card-text>
      <v-container fluid>
        <div class="text-h6">No Parent Record Found</div>

        <v-dialog
          v-model="dialog"
          max-width="1000px"
          transition="dialog-transition"
        >
          <v-sheet color="white">
            <Parent :user_id="getters_single_staff.id" />
          </v-sheet>
        </v-dialog>

        <v-btn
          class="mx-2"
          dark
          fab
          fixed
          bottom
          right
          @click="dialog = true"
          color="success"
        >
          <v-icon large dark>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import Parent from "@/components/Staff/Create/Parent";

  export default defineComponent({
    components: { Parent },
    setup() {
      const data = reactive({ dialog: false });
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const editParent = debounce(() => {
        emitter.emit("edit-parent", true);
      });

      return {
        ...toRefs(data),
        getters_single_staff,
        editParent,
      };
    },
  });
</script>
