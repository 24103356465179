<template>
  <v-container fluid v-if="getters_single_staff.death">
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="mx-2"
        dark
        fixed
        bottom
        fab
        @click="editQualification"
        color="success"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="[getters_single_staff.death]"
      hide-default-footer
      disable-pagination
      item-key="id"
    >
      <template #item.witness="{ value }">
        <span class="text-capitalize" v-text="value"> </span>
      </template>
      <template #item.nominee="{ value }">
        <div v-for="(row, key) in value" :key="key">
          <span v-for="(item, key) in row" :key="key">
            <v-row class="text-capitalize">
              <v-col>
                <b class="">{{ `${key}: ` }}</b>
              </v-col>
              <v-col>
                <span class="text-capitalize">{{ ` ${item} ` }}</span>
              </v-col>
            </v-row>
          </span>
        </div>
      </template>
    </v-data-table>
  </v-container>
  <v-card v-else flat>
    <v-card-text>
      <v-container fluid>
        <div class="text-h6">No Death Gratuity Record Found</div>

        <v-dialog
          v-model="dialog"
          max-width="1100px"
          transition="dialog-bottom-transition"
        >
          <v-sheet color="white">
            <DeathGratuity :user_id="getters_single_staff.id" />
          </v-sheet>
        </v-dialog>

        <v-footer app color="transparent">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = true" class="mx-2" dark fab color="success">
            <v-icon large dark>mdi-plus</v-icon>
          </v-btn>
        </v-footer>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import DeathGratuity from "@/components/Staff/Create/DeathGratuity";
  export default defineComponent({
    components: { DeathGratuity },
    setup() {
      const data = reactive({
        headers: [
          { text: "WITNESS NAME", value: "witness" },
          { text: "NOMINEE", value: "nominee" },
        ],
        dialog: false,
      });
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const editQualification = debounce(() => {
        emitter.emit("edit-gratuity", true);
      });

      return {
        ...toRefs(data),
        getters_single_staff,
        editQualification,
      };
    },
  });
</script>
