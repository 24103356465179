<template>
  <v-container fluid v-if="getters_single_staff.employment">
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Employee ID</th>
            <td>{{ getters_single_staff.employment.employee_id }}</td>
          </tr>
          <tr>
            <th>Supervisor</th>
            <td>
              <template v-if="getters_single_staff.employment.organogram">
                {{
                  `${getters_single_staff.employment.organogram.office_name} ${getters_single_staff.employment.organogram.office_type}`
                }}
              </template>
            </td>
          </tr>
          <tr>
            <th>Staff Division</th>
            <td>
              <template v-if="getters_single_staff.employment.staff_position">
                {{
                  getters_single_staff.employment.staff_position.staff_division
                    .division
                }}
              </template>
            </td>
          </tr>
          <tr>
            <th>Staff Category</th>
            <td>
              <template v-if="getters_single_staff.employment.staff_position">
                {{
                  getters_single_staff.employment.staff_position.staff_category
                    .category
                }}
              </template>
            </td>
          </tr>
          <!-- <tr>
            <th>Faculty</th>
            <td>{{ getters_single_staff.employment.faculty.faculty_name }}</td>
          </tr>
          <tr>
            <th>Department</th>
            <td>
              {{ getters_single_staff.employment.department.department_name }}
            </td>
          </tr> -->
          <tr>
            <th>Campus</th>
            <td>{{ getters_single_staff.employment.campus.campus_name }}</td>
          </tr>
          <tr>
            <th>SSNT ID</th>
            <td>{{ getters_single_staff.employment.ssnit_id }}</td>
          </tr>
          <tr>
            <th>Tin Number</th>
            <td>{{ getters_single_staff.employment.tin_number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-footer color="transparent">
      <v-spacer></v-spacer>
      <v-btn class="mx-2" dark fab @click="editEmployment" color="success">
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </v-footer>
  </v-container>
  <v-card v-else flat>
    <v-card-text>
      <v-container fluid>
        <div class="text-h6">No Employment Record Found</div>

        <v-dialog
          v-model="dialog"
          max-width="1000px"
          transition="dialog-transition"
        >
          <v-sheet color="white">
            <Employment :user_id="getters_single_staff.id" />
          </v-sheet>
        </v-dialog>

        <v-btn
          class="mx-2"
          dark
          fab
          fixed
          bottom
          right
          @click="dialog = true"
          color="success"
        >
          <v-icon large dark>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { defineComponent, inject, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import Employment from "@/components/Staff/Create/Employment";

  export default defineComponent({
    components: { Employment },
    setup() {
      const staff_id = inject("staff_id");
      const data = reactive({
        tab: null,
        edit: false,
        dialog: false,
      });

      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const { edit } = toRefs(data);

      const editEmployment = debounce(() => {
        edit.value = true;
        emitter.emit("edit-employment", true);
      });

      return {
        staff_id,
        ...toRefs(data),
        getters_single_staff,
        editEmployment,
      };
    },
  });
</script>
