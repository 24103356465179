<template>
  <v-container fluid v-if="getters_single_staff.qualification.length > 0">
    <div class="d-flex flex-row-reverse">
      <v-btn
        class="mx-2"
        dark
        fixed
        bottom
        fab
        @click="editQualification"
        color="success"
      >
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="getters_single_staff.qualification"
      hide-default-footer
      disable-pagination
      item-key="id"
    >
      <template #item.filename="{ value }">
        <v-btn @click="doAction(value)" large icon color="red">
          <v-icon>mdi-48px mdi-file-pdf-box</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
  <v-card v-else flat>
    <v-card-text>
      <v-container fluid>
        <div class="text-h6">No Qualification/Qualification Record Found</div>

        <v-dialog
          v-model="dialog"
          max-width="1100px"
          transition="dialog-bottom-transition"
        >
          <v-sheet color="white">
            <Qualification :user_id="getters_single_staff.id" />
          </v-sheet>
        </v-dialog>

        <v-btn
          @click="dialog = true"
          class="mx-2"
          dark
          fab
          fixed
          bottom
          right
          color="success"
        >
          <v-icon large dark>mdi-plus</v-icon>
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import Qualification from "@/components/Staff/Create/Qualification";
  export default defineComponent({
    components: { Qualification },
    setup() {
      const data = reactive({
        headers: [
          { text: "QUALIFICATION", value: "qualification" },
          { text: "INSTITUTION", value: "institution" },
          { text: "YEAR", value: "month_year" },
          { text: "FILE", value: "filename" },
        ],
        dialog: false,
      });
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const editQualification = debounce(() => {
        emitter.emit("edit-qualification", true);
      });

      const doAction = (item) => {
        window.open(
          `http://localhost:8000/${getters_single_staff.value.id}/${item}`,
          "_blank"
        );
      };

      return {
        ...toRefs(data),
        getters_single_staff,
        editQualification,
        doAction,
      };
    },
  });
</script>
