<template>
  <v-container fluid>
    <v-simple-table v-if="getters_single_staff">
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Title</th>
            <td>{{ getters_single_staff.title }}</td>
          </tr>
          <tr>
            <th>Gender</th>
            <td>{{ getters_single_staff.gender }}</td>
          </tr>
          <tr>
            <th>Other Names</th>
            <td>{{ getters_single_staff.othernames }}</td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>{{ getters_single_staff.lastname }}</td>
          </tr>
          <tr>
            <th>Date of Birth</th>
            <td>
              {{ moment(getters_single_staff.dob).format("Do MMMM YYYY") }}
            </td>
          </tr>
          <tr>
            <th>Corporte Email</th>
            <td>{{ getters_single_staff.corporate_email }}</td>
          </tr>
          <tr>
            <th>Personal Number</th>
            <td>{{ getters_single_staff.personal_number }}</td>
          </tr>
          <tr>
            <th>Office Number</th>
            <td>{{ getters_single_staff.office_number }}</td>
          </tr>
          <tr>
            <th>Personal Email</th>
            <td>{{ getters_single_staff.personal_email }}</td>
          </tr>
          <tr>
            <th>Nationality</th>
            <td>{{ getters_single_staff.nationality }}</td>
          </tr>
          <tr>
            <th>Address</th>
            <td>{{ getters_single_staff.address }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-footer color="transparent">
      <v-spacer></v-spacer>
      <v-btn class="mx-2" dark fab @click="editContact" color="success">
        <v-icon dark>mdi-pencil</v-icon>
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script>
  import { defineComponent, reactive, toRefs } from "vue";

  import { /* useActions, */ useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  export default defineComponent({
    setup() {
      const data = reactive({
        tab: null,
        edit: false,
        status: [
          { type: "Basic" },
          { type: "Contact" },
          { type: "Employment" },
          { type: "Education & Qualification" },
          { type: "Bank" },
          { type: "Spouse & Children" },
          { type: "Parent" },
          { type: "Next of Kin" },
          { type: "Death Gratuity" },
          { type: "Documents" },
        ],
        title: null,
      });
      // const { getOrganogram } = useActions(["getOrganogram"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      // getOrganogram();
      const { edit } = toRefs(data);

      const editContact = debounce(() => {
        edit.value = true;
        emitter.emit("edit-contact", true);
      });

      return {
        ...toRefs(data),
        getters_single_staff,
        editContact,
      };
    },
  });
</script>
