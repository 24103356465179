<template>
  <v-card flat>
    <v-card-title primary-title>
      <strong> Child/Children </strong>
    </v-card-title>

    <v-card-text>
      <validation-observer ref="observer">
        <v-form @submit.prevent>
          <v-card outlined>
            <v-card-text>
              <v-slide-y-transition group>
                <v-row
                  justify="center"
                  align="center"
                  :class="{ mycolor: task.duplicate }"
                  v-for="(task, i) in basketChildren"
                  :key="i"
                >
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="task.name"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        outlined
                        hide-details="auto"
                        label="Name of child"
                        v-model="task.name"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-menu
                      v-model="task.menuDob"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="task.dob"
                          rules="required"
                        >
                          <v-text-field
                            v-model="task.dob"
                            label="Date of birth"
                            readonly
                            outlined
                            hide-details="auto"
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="task.dob"
                        @input="task.menuDob = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="task.birthplace"
                      rules="required|alpha_num|alpha_spaces"
                    >
                      <v-text-field
                        hide-details="auto"
                        outlined
                        v-model="task.birthplace"
                        :error-messages="errors"
                        label="Birthplace"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="task.relationship"
                      rules="required|alpha"
                    >
                      <v-text-field
                        hide-details="auto"
                        outlined
                        v-model="task.relationship"
                        :error-messages="errors"
                        label="Relationship"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="2" class="text-center">
                    <v-slide-y-reverse-transition>
                      <v-btn
                        icon
                        v-if="i + 1 === basketChildren.length"
                        color="success"
                        @click="newRow()"
                        :disabled="!valid"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </v-slide-y-reverse-transition>
                    <v-slide-y-reverse-transition>
                      <v-btn
                        v-if="i + 1 >= 2"
                        icon
                        color="red"
                        @click="removeRow(i)"
                      >
                        <v-icon> mdi-delete-outline </v-icon>
                      </v-btn>
                    </v-slide-y-reverse-transition>
                  </v-col>
                </v-row>
              </v-slide-y-transition>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </v-card-text>
    <v-card-actions class="ml-2">
      <v-btn v-if="!props.user_id" color="primary" @click="sendData">
        Continue
      </v-btn>
      <v-btn :disabled="!valid" color="success" @click="addChildren" v-else>
        save changes
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import { defineComponent, reactive, toRefs, watch } from "vue";
  import { useActions } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    digits,
    regex,
    alpha_dash,
    alpha_spaces,
    required,
    alpha,
    alpha_num,
  } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("alpha_num", {
    ...alpha_num,
    message: "{_field_} can not be empty",
  });

  extend("alpha", {
    ...alpha,
    message: "{_field_} must be alphabet",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  must be ten digits starting with zero(0)",
  });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    props: ["user_id"],
    components: { ValidationProvider, ValidationObserver },
    setup(props) {
      const { userChildren } = useActions(["userChildren"]);
      const spouseChildrenForm = reactive({
        observer: null,
        menuDob: false,
        valid: true,
        basketChildren: [
          {
            name: "",
            dob: "",
            birthplace: "",
            relationship: "",
            user_id: props.user_id,
            menuDob: false,
          },
        ],
        actionResponse: null,
        color: null,
        msgBody: null,
        msgIcon: null,
      });

      const {
        basketChildren,
        observer,
        valid,
        actionResponse,
        color,
        msgBody,
        msgIcon,
      } = toRefs(spouseChildrenForm);

      watch(
        basketChildren,
        () => {
          actionResponse.value = false;
          const uniqueItems = [];
          basketChildren.value.filter((item) => {
            if (
              uniqueItems.find(
                (i) =>
                  i.name == item.name &&
                  i.dob == item.dob &&
                  i.birthplace == item.birthplace &&
                  i.relationship == item.relationship
              )
            ) {
              item["duplicate"] = true;
              valid.value = false;
              actionResponse.value = true;
              color.value = "error";
              msgBody.value = "Duplicate Found";
              msgIcon.value = "mdi-close-circle";
              return true;
            }
            uniqueItems.push(item);
            valid.value = true;
            delete item["duplicate"];
            return false;
          });
        },
        { deep: true }
      );

      const sendData = async () => {
        await observer.value.validate().then((result) => {
          if (result && valid.value) {
            let data = {
              children: basketChildren.value,
              user_id: props.user_id,
            };
            basketChildren.value.forEach((item) => delete item.menuDob);

            userChildren(data);
          }
        });
      };

      const addChildren = async () => {
        await observer.value.validate().then((result) => {
          if (result && valid.value) {
            basketChildren.value.forEach((item) => delete item.menuDob);
            let data = {
              children: basketChildren.value,
              user_id: props.user_id,
            };
            userChildren(data);
          }
        });
      };

      const newRow = () => {
        basketChildren.value.push({
          name: "",
          dob: "",
          birthplace: "",
          relationship: "",
          user_id: props.user_id,
          menuDob: false,
        });
      };

      const removeRow = (i) => {
        basketChildren.value.splice(i, 1);
      };

      return {
        ...toRefs(spouseChildrenForm),
        sendData,
        newRow,
        removeRow,
        props,
        addChildren,
      };
    },
  });
</script>
<style scoped>
  .mycolor {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
